import React from 'react';
import InfoFinance from '@/assets/images/info_finance.png';
import Link from 'next/link';
import { SocialLink } from '@/constants';
import { LogoFooter } from '@/assets/icons';

import { FacebookIcon, TiktokIcon, YoutubeIcon } from '@/assets/icons';
import { LazyImage } from '.';

export default function Footer() {
  return (
    <div className="bg-neutral-dark py-6 text-white md:py-[110px]">
      <div className="container grid grid-cols-1 gap-4 lg:grid-cols-4">
        <div className="lg:col-span-2">
          <LogoFooter className="h-12" />
          <div className="mt-6 flex flex-col gap-y-2 text-justify">
            <p>
              Website được phát triển bởi CÔNG TY TNHH MTV PIPSCOLLECTOR và là
              chuyên trang phi lợi nhuận dành cho mọi nhà đầu tư Việt.
            </p>
            <p>© 2023, All rights reserved.</p>
            <p>
              Địa chỉ: Văn phòng 2, Tầng 8, Tòa nhà Pearl Plaza, 561A Điện Biên
              Phủ, Phường 25, Quận Bình Thạnh, TP Hồ Chí Minh, Việt Nam
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-y-[14px] lg:pl-20">
          <p className="text-sub2 font-semibold">Follow Us</p>
          <div className="flex gap-x-4">
            <Link
              href={SocialLink.facebook}
              target="_blank "
              rel="nofollow"
              aria-label="Link Facebook"
              aria-hidden="true"
            >
              <FacebookIcon />
            </Link>
            <Link
              href={SocialLink.tiktok}
              target="_blank"
              rel="nofollow"
              aria-label="Link Tiktok"
              aria-hidden="true"
            >
              <TiktokIcon />
            </Link>
            <Link
              href={SocialLink.youtube}
              target="_blank"
              rel="nofollow"
              aria-label="Link Youtube"
              aria-hidden="true"
            >
              <YoutubeIcon />
            </Link>
          </div>
          {/* <p>
            Hotline: <a href="tel:0902122621">+84 902 122 621</a>
          </p> */}
          <p>
            Email:
            <a href="mailto:tapchitrading.app@gmail.com">
              tapchitrading.app@gmail.com
            </a>
          </p>
          <div className="flex">
            <p>Được bảo trợ bởi:</p>
            <LazyImage
              height={32}
              src={InfoFinance}
              alt="Example Image"
              width={75}
            />
          </div>
        </div>
        <div className="flex w-auto items-center justify-start lg:justify-center">
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=ecb30ffc-26e0-455f-be87-5fbea91a0bdc"
            title="DMCA.com Protection Status"
            // class="dmca-badge"
          >
            <LazyImage
              width={120}
              height={16}
              src="https://images.dmca.com/Badges/dmca_protected_16_120.png?ID=ecb30ffc-26e0-455f-be87-5fbea91a0bdc"
              alt="DMCA.com Protection Status"
            />
          </a>
          <script
            src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"
            defer
          />
        </div>
      </div>
    </div>
  );
}
